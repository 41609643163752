<script>
import appConfig from "@/app.config";

export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      users: [],
      Users: {},
      surveyTeam: [],
      marketing_team_members: [],
      page: 1,
      limit: 20,
      tot_pages: 0,
      searchUsers: null,
      SearchMode: false,
      emps: [],
      new_roles: [],
      newRoles: [{}],
      addRoles: [{}],
      branches: [],
      user_info: {},
      new_pass: "",
      users_switch: "",
      user_status: 0,
      data: {},
      roles: [],

      // appprices:[]
    };
  },
  methods: {
    getSurveyTeam() {
      this.http
        .post("marketing-survey-team-members/by-survey-id", {
          marketing_survey_id: this.$route.params?.surveyId,
        }, null, true)
        .then((res) => {
          this.surveyTeam = res.data.map((i) => {
            return {
              ...i.marketing_team_member,
              marketing_survey_member_id: i.id,
            };
          });
          console.log({ surveyTeam: this.surveyTeam });
        });
    },
    addUsers() {
      console.log("**********####", this.state);
      var data = {
        title: "popups.add_team",
        inputs: [
          {
            model: "marketing_survey_team_member_id",
            type: "select",
            options: (() => {
              return this.surveyTeam.map((data) => {
                return {
                  value: data?.marketing_survey_member_id,
                  label: data?.name,
                };
              });
            })(),
            label: "popups.add_team",
          },
          {
            model: 'target',
            type: 'number',
            label:'marketing.team_members.table.target'
          }
        ],
        buttons: [
          {
            text: "popups.add",
            closer: true,
            color: "primary",
            handler: (obj) => {
              obj.marketing_area_id = this.$route.params?.areaId;
              this.http
                .post("marketing-survey-team-member-areas", obj, null, true)
                .then(() => {
                  this.get(this.page);
                })
                .catch((e) => console.log(e));
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    search() {
      this.SearchMode = true;
      console.log(this.searchUsers);
      this.tot_pages = 0;
      this.http
        .post("marketing-survey-team-member-areas/by-area-id/search", {
          limit: this.limit,
          page: this.page,
          search: this.searchUsers,
          col: "name",
          marketing_area_id: this.$route.params?.areaId,
          marketing_survey_id: this.$route.params?.surveyId,

        }, null, true)
        .then((res) => {
          this.users = res.data;
        });
    },
    cancelSearchMode() {
      this.searchUsers = "";
      this.SearchMode = false;
      this.get(this.page);
    },
    getUsers() {
      this.http
        .post("marketing-survey-team-member-areas/by-area-id", {
          marketing_area_id: this.$route.params?.areaId,
          marketing_survey_id: this.$route.params?.surveyId,
        }, null, true)
        .then((res) => {
          this.users = res.data;
        });
    },
    getMarketingTeamMembers() {
      this.http.get("marketing-team-members", null, null, true).then((res) => {
        this.marketing_team_members = res.data;
      });
    },
    deleteUser(user) {
      var data = {
        title: "popups.deleteuser",
        msg: "popups.deletemsg",
        buttons: [
          {
            text: "Delete",
            closer: true,
            color: "danger",
            handler: () => { },
          },
          {
            text: "Cancel",
            closer: true,
            color: "light",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.confirm(data).then(() => {
        console.log(user);
        this.http
          .post("marketing-survey-team-member-areas/delete", {
            id: user.id,
          }, null, true)
          .then(() => {
            this.get(this.page);
          });
      });
    },
    userInfo(user) {
      this.user_info = user;
      console.log(this.user_info);
    },
    editPass() {
      console.log(this.user_info.id);
      this.http.post("marketing-team-members/update-password", {
        id: this.user_info.id,
        password: this.new_pass,
      });
    },

    get(page) {
      console.log(this.userstable);
      console.log({ route_param: this.$route.params?.id });
      this.http
        .post("marketing-survey-team-member-areas/by-area-id/pagination", {
          limit: this.limit,
          page: page,
          marketing_area_id: this.$route.params?.areaId,
          marketing_survey_id: this.$route.params?.surveyId,
        }, null, true)
        .then((res) => {
          this.page = page;
          this.tot_pages = Math.ceil(res.tot / this.limit);
          this.users = res.data;
          console.log({ Team: this.users });
        });
    },
  },
  created() {
    this.get(1);
    this.getSurveyTeam();
  },
};
</script>

<template>
  <div>
    <div class="row">
      <div>
        <div class="row align-items-center">
          <div class="col-4"></div>

          <div class="col-8">
            <div class="search-box chat-search-box d-flex align-items-center"
              style="width: 250px; margin-inline-start: auto">
              <div class="position-relative mx-3 d-flex align-items-center">
                <input @keyup="search()" type="text" class="form-control fa-lg text-light"
                  :dir="$i18n.locale == 'ar' ? 'rtl' : ''" :placeholder="$t('users.searchplaceholder')"
                  v-model="searchUsers" style="background-color: #2a3042 !important" />
                <i class="bx bx-search-alt search-icon text-light"></i>
                <button @click="cancelSearchMode()" v-if="SearchMode" class="btn-close text-light"
                  style="position: absolute; top: 12px; left: 250px"></button>
              </div>
              <button type="button" class="btn btn-light float-end" @click="addUsers">
                <span class="bx bxs-plus-circle float-end fa-2x" style="color: #2a3042 !important"></span>
              </button>
            </div>
          </div>
        </div>
        <!-- end row -->

        <hr class="mb-4" />

        <div class="row">
          <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table">
            <thead>
              <tr class="text-light text-center" style="background-color: #2a3042 !important">
                <th scope="col">{{ $t("users.userstable.id") }}</th>
                <th scope="col">
                  {{ $t("marketing.team_members.table.name") }}
                </th>
                <th scope="col">
                  {{ $t("marketing.team_members.table.tel") }}
                </th>
                <th scope="col">
                  {{ $t("marketing.team_members.table.email") }}
                </th>
                <th scope="col">
                  {{ $t("marketing.team_members.table.rate") }}
                </th>
                <th scope="col">
                  {{ $t("marketing.team_members.table.target") }}
                </th>
                <th scope="col">
                  {{ $t("marketing.team_members.table.birthdate") }}
                </th>
                <th scope="col">{{ $t("users.userstable.created") }}</th>
                <th scope="col">{{ $t("users.userstable.updated") }}</th>
                <th scope="col">{{ $t("users.userstable.operations") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr class="text-center" v-for="(user, index) in users" :key="user">
                <td>{{ index + 1 }}</td>
                <td>{{ user?.marketing_survey_team_member?.marketing_team_member?.name }}</td>
                <td>{{ user?.marketing_survey_team_member?.marketing_team_member?.tel }}</td>
                <td>{{ user?.marketing_survey_team_member?.marketing_team_member?.email }}</td>
                <td>
                  {{ user?.marketing_survey_team_member?.rate ? user?.marketing_survey_team_member?.rate : "--" }}
                </td>
                <td>
                  {{ user?.target ? user?.target : "--" }}
                </td>
                <td v-if="user?.marketing_survey_team_member?.marketing_team_member?.birthdate">
                  {{ user?.marketing_survey_team_member?.marketing_team_member?.birthdate.split("T")[0] }}
                </td>
                <td v-if="user.created">{{ user?.created.split("T")[0] }}</td>
                <td v-if="user.updated">{{ user?.updated.split("T")[0] }}</td>
                <td class="d-flex justify-content-end">
                  <button @click="getSwitch(user, 1)" v-if="user?.status == 0" class="btn btn-primary btn-sm me-2">
                    {{ $t("popups.enable") }}
                  </button>
                  <button type="button" @click="getSwitch(user, 0)" v-if="user?.status == 1"
                    class="btn btn-secondary btn-sm me-2">
                    {{ $t("popups.disable") }}
                  </button>
                  <a class="btn btn-danger btn-sm me-2" :class="{}" href="javascript:void(0)" role="button"
                    @click="deleteUser(user)">{{ $t("popups.delete") }}</a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<!-- marketing-survey-team-members -->
