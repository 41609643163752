<script>
import appConfig from "@/app.config";
import MarketingSurveyAreasTeam from "./components/MarketingSurveyAreasTeam.vue";

export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    MarketingSurveyAreasTeam,
  },
};
</script>

<template>
  <div class="row">
    <div class="row align-items-center">
      <div class="d-flex align-items-center mb-4">
        <router-link :to="`/dashboard/marketing_survey`">
          <h4 class="m-0">{{ $t("menu.menuitems.marketing.subItems.marketing_survey") }}</h4>
        </router-link>
        <div class="mx-2">\</div>
        <router-link :to="{ name: 'marketing_survey_details', params: { id: this.$route.params?.surveyId } }">
          <div>{{ $t("menu.menuitems.marketing.survey_details.text") }}</div>
        </router-link>
        <div class="mx-2">\</div>
        <div>{{ $t("menu.menuitems.marketing.tabs.area_team_members") }}</div>
      </div>
    </div>
  </div>
  <marketing-survey-areas-team></marketing-survey-areas-team>
</template>
